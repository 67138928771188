*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

:root 
{
  --primary-color: #000000;
  --primary-text: #ffffff;
}

html {
  scroll-behavior: smooth;
}

body 
{
  color: var(--primary-text);
}

.alert__wrapper 
{
    background-color: #000;
    width: 400px;
    height: 50px;
    position: absolute;
    bottom: 100px;
    right: 10px;
    color: #fff;
    z-index: 999999;
    border-radius: 0.7vmax;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1vmax;
    transition: all 0.3s ease-in-out;
    box-shadow: 2px 2px 10px rgb(0 0 0 / 60%)
}

.alert2__wrapper 
{
    background-color: #000;
    width: 400px;
    height: 50px;
    position: absolute;
    top:100px;
    right: 10px;
    color: #fff;
    z-index: 999999;
    border-radius: 0.7vmax;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1vmax;
    transition: all 0.3s ease-in-out;
    box-shadow: 2px 2px 10px rgb(0 0 0 / 60%)
}

.alert__wrapper > h5 
{
    font-size: 1vmax;
    font-weight: 300;
    text-transform: capitalize;
}

.alert__wrapper > p 
{
    font-size: 1vmax;
    padding-top: 1vmax;
    cursor: pointer;
}

.alert2__wrapper > h5 
{
    font-size: 1vmax;
    font-weight: 300;
    text-transform: capitalize;
}

.alert2__wrapper > p 
{
    font-size: 1vmax;
    padding-top: 1vmax;
    cursor: pointer;
}

@media  screen and (max-width:860px) {
  .alert__wrapper {
    bottom: -200px;
  }

  .alert__wrapper > h5 
  {
    font-size: 1.5vmax;
  }
  .alert2__wrapper > p 
  {
    font-size: 1.5vmax;
  }
}

@media  screen and (max-width:550px) {
  .alert__wrapper {
    width: 300px;
  }
}