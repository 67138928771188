.Card__wrappper 
{
    background-color: var(--primary-text);
    width: 95%;
    margin: 0.5vmax;
    text-align: center;
    padding: 0.5vmax;
    box-shadow: 2px 2px 10px rgb(0 0 0 / 60%)
}

.Card__wrappper:nth-child(1) { transform: rotate(-1.3deg); }
.Card__wrappper:nth-child(4) { transform: rotate(0.9deg); } 
.Card__wrappper:nth-child(3) { transform: rotate(-0.56deg); } 
.Card__wrappper:nth-child(2) { transform: rotate(1deg); } 

.Card__wrappper > div:first-child > img 
{
    /* padding: 0.5vmax; */
    width: 220px;
    height: 220px;
    object-fit: cover;
}

.Card__wrappper > div:last-child 
{
    color: rgb(1, 1, 184);
    font-size: 2vmax;
    font-weight: 700;
    text-align: center;
    /* font-family: 'Bilbo', cursive; */
    font-family:"wordy-diva";
}

@media  screen and (max-width:860px) {
    .Card__wrappper {
        width: 100%;
    }
    .Card__wrappper > div:last-child 
    {
        font-size: 2.5vmax;
    }
}

@media  screen and (max-width:550px) {
    .Card__wrappper {
        padding: 1vmax;
    }
}