.claim__wrapper 
{
    background: rgb(255,239,0);
    background: linear-gradient(0deg, rgba(255,239,0,1) 0%, rgba(255,170,11,1) 48%);
    min-height: 80.5vh;
    display:  flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 5vmax 0;
}
.claim__wrapper > .left 
{
    background-color: var(--primary-text);
    padding: 0.5vmax;
}
.claim__wrapper > .left > img 
{
    width: 400px;
    height: auto;
}

.claim__wrapper > .right
{
    background-color: var(--primary-color);
    width: 500px;
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.claim__wrapper > .right > form > div > input 
{
    width: 450px !important;
    height: 8vh !important;
    background-color: var(--primary-color) !important;
    border: 2px solid #FFAA0B !important;
    border-radius: 0.5vmax;
    margin-top: 1vmax;
    color: #fff;
    padding: 0 1vmax;
}

.claim__wrapper > .right > form > div > button 
{
    width: 450px;
    padding: 0.5vmax 4vmax;
    margin-top: 1vmax;
    background-color: var(--primary-text);
    color: var(--primary-color);
    border-radius: 0.5vmax;
    border: 1px solid var(--primary-color);
    font-size: 1.8vmax;
    font-weight: 900;
    font-family:"museo-sans";
}

.message 
{
    color: #FFAA0B;
    text-transform: capitalize;
}

@media  screen and (max-width:768px) {
    .claim__wrapper  
    {
        flex-direction: column;
        padding: 15vmax 0;
    }
    .claim__wrapper > .left 
    {
        margin: 2vmax 0;
    }
    .claim__wrapper > .right 
    {
        margin: 2vmax 3vmax;
        width: 90%;
    }
    .claim__wrapper > .right > form > div > input  
    {
        width: 300px !important;
    }
    .claim__wrapper > .right > form > div > button 
    {
        width: 300px;
        height: 8vh;
    }
    .claim__wrapper > .left > img 
    {
        width: 300px;
        height: auto;
    }
}

@media  screen and (max-width:340px) {
    .claim__wrapper > .right > form > div > input  
    {
        width: 200px !important;
    }
    .claim__wrapper > .right > form > div > button 
    {
        width: 200px;
        height: 8vh;
    }

    .claim__wrapper > .left > img 
    {
        width: 200px;
        height: auto;
    }
}

@media  screen and (max-width:922px) {
    .claim__wrapper > .left > img 
    {
        width: 300px;
        height: auto;
    }
    .claim__wrapper > .right
    {
        width: 300px;
    }
    .claim__wrapper > .right > form > div > input  
    {
        width: 280px !important;
    }
    .claim__wrapper > .right > form > div > button 
    {
        width: 280px;
        height: 8vh;
    }
}



