.about__wrapper 
{
    background-color: var(--primary-color);
    min-height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding: 5vmax 15vmax; */

    padding-top:7vh !important; padding-bottom:7vh !important;
    padding-left: 15vmax;
    padding-right: 15vmax;
}
.about__wrapper > .left > div > h3 
{
    font-family:"wordy-diva";
    color: #fff;
    font-size: 3vmax;
    padding-left: 10px;
}

.about__wrapper > .left > div > p
{
    color: var(--primary-text);
    padding-left: 1vmax;
    font-size: 1vmax;
    font-family:"museo-sans";
    line-height: 1.6;
}

.about__wrapper2 {
    align-items: center;
    background-color: var(--primary-color);
    justify-content: center;
    min-height: 50vh;
    padding-bottom: 7vh!important;
    padding-left: 15vmax;
    padding-right: 15vmax;
    padding-top: 0px important;
}

.about__us {
    background: #000;
    justify-content: center;
    grid-gap: 1vmax;
    display: grid;
    grid-template-columns: repeat(4,1fr);
    margin: 30px auto;
    max-width: 1070px;
    width: unset!important;
}

.about__us .profile {
    text-align: center;
    color: #ffffff;
    margin-bottom: 30px;
}

.about__us .profile img {
    border: 3px solid #ff0;
    border-radius: 100%;
    width: 95%; max-width:220px;
    padding: 0px;
}

.about__us .profile img.linkedIn {
    border: 0px !important;
    max-width: 32px !important;
    margin-top: 10px;
}

.about__us .profile h4 
{
    font-family:"wordy-diva";
    color: #fff;
    font-size: 1.8vmax;
    padding-top: 20px;
    margin-bottom:-3px
}

.moreabout h3 
{
    font-family:"wordy-diva";
    color: #fff;
    font-size: 3vmax;
    padding-left: 10px; text-align:center;
}


@media  screen and (max-width:991px) {
    .about__wrapper > .left > div > p
    {
        font-size: 1.6vmax;
    }
    .about__wrapper > .left > div > h3 
    {
        font-size: 3vmax;
    }
    .about__wrapper > .left > div > h3 > img 
    {
        width: 150px;
        height: auto;
    }

    .about__us {
        grid-template-columns: repeat(2,1fr);
    }
}

@media  screen and (max-width:800px) {
    .about__wrapper, .about__wrapper2 
    {
        flex-direction: column;
        padding: 5vmax 2vmax;
    }

    .about__wrapper > .right > img 
    {
        width: 360px;
        /* object-fit: cover; */
    }

    .about__wrapper > .left > div > h3 
    {
        font-size: 3vmax;
    }
    .about__wrapper > .left > div > h3 > img 
    {
        width: 100px;
        height: auto;
    }

}

@media screen and (max-width: 680px) {
.about__us {
    grid-template-columns: 1fr;
}
}
