.footer__wrapper 
{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--primary-color);
    padding: 1.12vmax 5vmax;
    flex-direction: column;
}

.footer__wrapper>div:last-child {
    display: flex;
    margin-left: 2vmax;
    margin-top: 10px;
}

.footer__wrapper > div:last-child > div > a 
{
    text-decoration: none;
    margin-right: 2vmax;
    color: var(--primary-text);
    font-family:"museo-sans";
}

@media  screen and (max-width:550px) {
    .footer__wrapper 
    {
        flex-direction: column;
    }
}