.Hero__wrapper 
{
    background: rgb(255,239,0);
    background: linear-gradient(0deg, rgba(255,239,0,1) 0%, rgba(255,170,11,1) 48%);
    /* min-height: 70vh; */
    min-height: unset;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* padding: 8vmax 0; */
    padding: 17vh 0 7vh;
}

.Hero__wrapper > .top > h1
{
    color: var(--primary-text);
    /* font-family: 'Bilbo', cursive; */
    font-family:"wordy-diva";
    font-size: 44px;
}

.Hero__wrapper > .mid 
{
    display: grid;
    grid-template-columns: repeat(4,1fr);
    grid-gap:1vmax;
}
.Hero__wrapper > .bottom
{
    margin-top: 1vmax; text-align:center;
}
.Hero__wrapper > .bottom > a > button
{
    padding: 0.5vmax 4vmax;
    background-color: var(--primary-text);
    color: var(--primary-color);
    border-radius: 0.5vmax;
    /* border: 1px solid var(--primary-color); */
    font-size: 1.8vmax;
    font-weight: 900;
    font-family:"museo-sans";
    margin-top:50px !important;
    border: 2px solid #000;
}

@media  screen and (max-width:860px) {
    .Hero__wrapper 
    {
        padding: 15vmax 0;
    }
    .Hero__wrapper > .mid 
    {
        grid-template-columns: repeat(2,1fr);
    }
    .Hero__wrapper > .top > h1
    {
        font-size: 3vmax;
    }
    .Hero__wrapper > .bottom > a > button
    {
        padding: 0.5vmax 5vmax;
        font-size: 2vmax;
    }
}

@media  screen and (max-width:550px) {
    .Hero__wrapper > .mid 
    {
        grid-template-columns: 1fr;
    }
    .Hero__wrapper > .top > h1
    {
        font-size: 2.8vmax;
    }
}

.video-responsive-wrapper {
    max-width: 100%;
    width: 600px;
    left: 0;
    right:0;
    margin:auto;
    margin-top:60px;
    }

.video-responsive {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 0;
    height: 0;
    overflow: hidden;
}

.video-responsive iframe,
.video-responsive object,
.video-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.red {
    color: red;
    text-align: center;
    margin-top: 2vmax;
    font-size: 2em;
}