.TopNav__wrapper 
{
    background-color: var(--primary-color);
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 10vh;
    position: fixed;
    width: 100%;
    z-index: 9999;
}

.TopNav__wrapper > .mid 
{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.TopNav__wrapper > .mid > div 
{
    margin-right: 1vmax;
}

.TopNav__wrapper > .mid > div > a 
{
    text-decoration: none;
    color: var(--primary-text);
    text-transform: uppercase;
    font-family:"museo-sans";
    font-size: 16px;
}

.TopNav__wrapper > .right > button 
{
    padding: 0.3vmax 1.5vmax;
    background-color: transparent;
    border: 2px solid var(--primary-text);
    color: var(--primary-text);
    border-radius: 0.5vmax;
    font-family:"museo-sans";
    margin-right: 0.5vmax;
    position: relative;
}
.TopNav__wrapper > .right > .sub_menu 
{
    position: absolute;
    top: 65px;
    background-color: black;
    width: 200px;
    height: 20vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 0.7vmax;
}
.TopNav__wrapper > .right > .sub_menu > div 
{
    margin-bottom: 0.3vmax;
}
.TopNav__wrapper > .right > .sub_menu > div > button 
{
    padding: 0.3vmax 1.5vmax;
    background-color: transparent;
    border: 2px solid var(--primary-text);
    color: var(--primary-text);
    border-radius: 0.5vmax;
    font-family:"museo-sans";
}
.TopNav__wrapper > .right > a > button 
{
    padding: 0.3vmax 1.5vmax;
    background-color: transparent;
    border: 2px solid var(--primary-text);
    color: var(--primary-text);
    border-radius: 0.5vmax;
    font-family:"museo-sans";
}
.mob__nav_wrapper 
{
    display: none;
}
@media  screen and (max-width:768px) {
    .TopNav__wrapper > .mid > div > a 
    {
        font-size: 1.6vmax;
    }
}
@media  screen and (max-width:998px) {
    .TopNav__wrapper 
    {
        display: none;
    }
    .mob__nav_wrapper 
    {
        display: block;
        background-color: var(--primary-color);
        position: relative;
        position: fixed;
        width: 100%;
        z-index: 9999;
    }

    .mob__nav_wrapper > .container 
    {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 10vh;
    }

    .mob__nav_wrapper > .container > .right 
    {
        font-size: 5vmax;
        padding-bottom: 1vmax;
        color: var(--primary-text);
        cursor: pointer;
    }

    .mob__nav_wrapper > .container__absolute 
    {
        position: absolute;
        top: 0;
        right: 0;
        background-color: var(--primary-color);
        width: 60%;
        height: 100vh;
        z-index: 999;
        padding: 2vmax;
        transition: 0.5s all ease-in-out;
    }

    .mob__nav_wrapper > .container__absolute  > div > svg
    {
        color: var(--primary-text);
        font-size: 3vmax;
    }

    .mob__nav_wrapper > .container__absolute  > div > a 
    {
        text-decoration: none;
        color: var(--primary-text);
        font-family:"museo-sans";
        text-transform: uppercase;
    }
    .mob__nav_wrapper > .container__absolute  > div
    {
        margin: 2vmax 0;
    }

    .mob__nav_wrapper > .container__absolute  > div > button 
    {
        padding: 0.3vmax 1.5vmax;
        background-color: transparent;
        border: 2px solid var(--primary-text);
        color: var(--primary-text);
        border-radius: 0.5vmax;
        font-family:"museo-sans";
    }


    .mob__nav_wrapper > .container__absolute  > div > a > button 
    {
        padding: 0.3vmax 1.5vmax;
        background-color: transparent;
        border: 2px solid var(--primary-text);
        color: var(--primary-text);
        border-radius: 0.5vmax;
        font-family:"museo-sans";
    }
    .mob__nav_wrapper > .container__absolute > div > .mob__sub__menu 
    {
        margin: 2vmax 0;
    }
    .mob__nav_wrapper > .container__absolute > div > .mob__sub__menu > div > button
    {
        padding: 0.3vmax 1.5vmax;
        background-color: transparent;
        border: 2px solid var(--primary-text);
        color: var(--primary-text);
        border-radius: 0.5vmax;
        font-family:"museo-sans";
        margin: 1vmax 0;
    }
}